<template>
  <v-container fluid>
    <v-row>
      <upload
        v-bind:path="documentPath"
        v-on:uploadComplete="documentUploaded"
        button-text="Upload"
      ></upload>
      <v-col cols="12">
        <v-list>
          <v-list-item v-for="(doc,index) in currentIncident.incidentAttachments" :key="index">
            <v-list-item-content>
              <a v-bind:href="doc.downloadURL" target="_blank">{{doc.filename}}</a>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-col>
      <v-col cols="6"></v-col>
    </v-row>
    <v-btn color="primary" @click="next">Continue</v-btn>
  </v-container>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";

Vue.use(VeeValidate);

export default {
  name: "goals",

  data: () => ({
    vm: {},
    dialog: false,
    valid: true,
    documents: [],
    totalItems: 0,
    loading: false,
    documentPath: "",
    queryFilters: [],
    rowPageConfig: [
      50,
      100,
      200,
      { text: "$vuetify.dataIterator.rowsPerPageAll", value: -1 }
    ]
  }),
  props: {
    msg: String
  },
  mounted() {
    this.init();
    let basePath =
      this.currentOrganisation.id +
      "/" +
      this.currentCase.id +
      "/Incidents/" +
      this.currentIncident.id +
      "/";
    this.documentPath = basePath + "document/";
  },
  computed: {
    currentOrganisation() {
      return this.$store.getters.currentOrganisation;
    },
    currentCase() {
      return this.$store.getters.currentCase;
    },
    currentGoal(state) {
      return state.currentGoal;
    },
    currentIncident() {
      return this.$store.getters.currentIncident;
    }
  },
  methods: {
    init() {
      this.vm = {};
    },
    documentUploaded(data) {
      if (
        this.currentIncident.incidentAttachments == undefined ||
        !Array.isArray(this.currentIncident.incidentAttachments)
      ) {
        this.currentIncident.incidentAttachments = [];
      }
      this.currentIncident.incidentAttachments.push(data);
      this.$store.dispatch("setCurrentIncident", this.currentIncident);
      this.$forceUpdate();
    },

    deleteItem(item) {
      const index = this.currentIncident.incidentAttachments.indexOf(item);
      confirm("Are you sure you want to delete this item?") &&
        this.currentIncident.incidentAttachments.splice(index, 1);
    },

    next() {
      this.$emit("next-step");
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

